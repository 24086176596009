import { createClient, dedupExchange, errorExchange, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { makeOperation } from '@urql/core';
import { take } from 'rxjs/operators';
import { captureEvent } from '@sentry/nextjs';

import { authExchange } from '@urql/exchange-auth';
import { correctToken$ } from './TokenService';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'pronostix.cleverapps.io' : 'devpronostix.nbe.io';

const HTTP_BASE_URL = `https://${BASE_URL}`;
const ENDPOINT = '/v1/graphql';
export const HTTP_URL = `${HTTP_BASE_URL}${ENDPOINT}`;

export const createAnonymousClient = () => {
  return createClient({
    url: HTTP_URL,
    suspense: false,
    exchanges: [devtoolsExchange, dedupExchange, fetchExchange],
  });
};

const errorKeyAbsent = (message: string) => {
  console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
  console.error('URQL CACHE KEY ERROR');
  console.error(message);
  console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
  if (process.env.NODE_ENV !== 'production') {
    throw new Error(message);
  }
};

const getKeyOrThrow = <T>(obj: T, key: keyof T): string => {
  if (!obj[key]) {
    errorKeyAbsent(`Missing key ${key} for ${JSON.stringify(obj)}`);
  }
  return `${obj[key]}`;
};

export const createAuthClient = () => {
  return createClient({
    url: HTTP_URL,
    suspense: false,
    exchanges: [
      devtoolsExchange,
      errorExchange({
        onError: (error, operation) => {
          console.error(error, 'URQL ERROR');
          captureEvent({
            exception: error as any,
            message: error.message,
            extra: {
              ...operation,
              source: 'urql@errorExchange',
            },
          });
        },
      }),
      dedupExchange,
      cacheExchange({
        updates: {
          Mutation: {
            makeForecast(result, args, cache, info) {
              cache.invalidate({
                __typename: 'match',
                id: args.matchId as string,
              });

              cache.invalidate({
                __typename: 'profile_forecast_status',
                // @ts-ignore
                profile_id: result?.makeForecast?.forecast?.profileId as string,
              });
            },
            delete_group_by_pk(result, args, cache, info) {
              cache.invalidate({
                __typename: 'group',
                id: args.id as string,
              });
            },
            registerGroup(result, args, cache, info) {
              cache.invalidate({
                __typename: 'group_points_and_rank',
                // Yeah, a dirty hack because I didn't know how to
                group_id: args.cupId as string,
              });
            },
          },
        },
        keys: {
          forecast: (pronostic) => {
            return `${getKeyOrThrow(pronostic, 'profileId')}:${getKeyOrThrow(pronostic, 'matchId')}`;
          },
          profile_forecast_status: (data) => `${getKeyOrThrow(data, 'profile_id')}`,
          profile_points: (data) => `${getKeyOrThrow(data, 'profileId')}`,
          forecast_status: (data) => `${getKeyOrThrow(data, 'value')}`,
          forecast_aggregate: () => null,
          forecast_aggregate_fields: () => null,
          group_points_and_rank: (data) => `${getKeyOrThrow(data, 'group_id')}`,
          group_profil_points_and_rank: (data) =>
            `${getKeyOrThrow(data, 'group_id')}:${getKeyOrThrow(data, 'profile_id')}`,
          group_member_aggregate: () => null,
          group_member_aggregate_fields: () => null,
        },
      }),
      authExchange<{ token: string }>({
        getAuth: async (params) => {
          const maybeToken = await correctToken$.pipe(take(1)).toPromise();
          if (!maybeToken) {
            return null;
          }
          return { token: maybeToken };
        },
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState?.token) {
            captureEvent(new Error('[URQL MANUAL]: Request done without token'));
            return operation;
          }
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
              },
            },
          });
        },
        didAuthError: (params) => {
          console.error('didAuthError', params);
          return params.error.message.includes('JWT');
        },
      }),
      fetchExchange,
    ],
  });
};
