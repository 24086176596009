import { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { debounceTime } from 'rxjs/operators';
import { createAnonymousClient, createAuthClient } from '../services/urqlClient';
import { Provider as UrqlProvider } from 'urql';
import { CurrentUserProvider } from '../hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { NavbarLayout } from '../layout/NavbarLayout';
import '../services/i18n';
import '../css/tailwind.src.css';
// import splitbee from '@splitbee/web';
import Seo from '../layout/Seo';
import { fromEvent } from 'rxjs';
import { ToasterHub } from '../components/Toast';
import { AdminLayout } from '../layout/AdminLayout';
import { AuthContextProvider, useAuthContext } from '../hooks/useAuthContext';
import { watchForHeightChange$ } from '../services/setViewHeight';
/*
splitbee.init({
  // Enable cookie-less mode. Defaults to `false`
  disableCookie: true,

  // Set custom urls when using proxying
  scriptUrl: '/bee.js',
  apiUrl: '/_hive',
});
 */

function ClientProvider(props: React.PropsWithChildren<{}>) {
  const { token } = useAuthContext();

  const [urqlClient, setUrqlClient] = useState(createAnonymousClient());
  const [isAnonymousClient, setIsAnonymousClient] = useState(true);

  useEffect(() => {
    const hasNoToken = token === undefined || token === null;
    if (!hasNoToken && isAnonymousClient) {
      setUrqlClient(createAuthClient());
      setIsAnonymousClient(false);
    }
    if (hasNoToken && !isAnonymousClient) {
      setUrqlClient(createAnonymousClient());
      setIsAnonymousClient(true);
    }
  }, [isAnonymousClient, token]);

  return <UrqlProvider value={urqlClient}>{props.children}</UrqlProvider>;
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const subscription = watchForHeightChange$();
    return () => subscription.unsubscribe();
  }, []);

  let Layout;
  if (router.pathname.startsWith('/app')) {
    Layout = NavbarLayout;
  } else if (router.pathname.startsWith('/admin')) {
    Layout = AdminLayout;
  } else {
    Layout = React.Fragment;
  }
  return (
    <AuthContextProvider>
      <ClientProvider>
        <CurrentUserProvider>
          <>
            <Seo />
            <ToasterHub />
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </>
        </CurrentUserProvider>
      </ClientProvider>
    </AuthContextProvider>
  );
}

export default MyApp;
