import React, { Suspense, useEffect, useState } from 'react';

import { ForecastPanel } from '../components/ForecastPanel';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Navbar } from '../components/Navbar';
import { useRouter } from 'next/router';
import { LoadingFullScreen } from './LoadingFullScreen';
import { ReservedPseudo } from '../components/ReservedPseudo';
import { Modal, useModal } from '../components/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import gql from 'graphql-tag';
import { GroupInviteDataFragment, useAcceptInvitationMutation } from '../generated/graphql';
import { showToast } from '../components/Toast';
import { DEFAULT_GROUP_PICTURE } from '../constants';
import { useAuthContext } from '../hooks/useAuthContext';
import { RulesModal } from '../components/RulesModal';

gql`
  mutation acceptInvitation($invitationId: String!) {
    acceptInvitation(invitationId: $invitationId) {
      group_id
    }
  }
`;

export const NavbarLayout: React.FC = ({ children }) => {
  const router = useRouter();
  const { authStatus } = useAuthContext();
  const { user, loading: userLoading } = useCurrentUser();
  const [isOpen, setOpen, setClose] = useModal();
  const [isRulesOpen, setRulesOpen, setRulesClose] = useModal();
  const [inviteState, setInviteState] = useState<GroupInviteDataFragment>();

  useEffect(() => {
    if (authStatus === 'out') {
      console.log('HOME Nav to login');
      router.push('/login');
    }
  }, [authStatus, router]);

  useEffect(() => {
    if (!userLoading && !user?.pseudo) {
      console.log('HOME Nav to pseudo', { user, userLoading });
      router.push('/pseudo');
    }
  }, [router, user, userLoading]);

  useEffect(() => {
    if (localStorage.getItem('pendingInvitation')) {
      const groupData = JSON.parse(localStorage.getItem('pendingInvitation') ?? '{}');
      setInviteState(groupData);
      localStorage.removeItem('pendingInvitation');
      if (inviteState?.public_id) {
        setOpen();
      }
      setOpen();
    }
    if (!localStorage.getItem('rulesAlreadyRead')) {
      setRulesOpen();
      localStorage.setItem('rulesAlreadyRead', 'true');
    }
  }, []);

  if (!user) {
    return <LoadingFullScreen debugLabel="NavbarLayout no user" />;
  }
  if (!user?.pseudo) {
    return <LoadingFullScreen debugLabel="NavbarLayout no pseudo to user" />;
  }

  if (process.env.NEXT_PUBLIC_APP_OPEN === 'false') {
    return <ReservedPseudo />;
  }

  return (
    <Navbar>
      <Suspense fallback={'LOADER NOT FULL PAGE TODO a'}>
        <ForecastPanel>
          {children}
          <ForecastPanel.Panel />
        </ForecastPanel>
        <JoinGroupModal isOpen={isOpen} setClose={setClose} inviteData={inviteState} />
        <RulesModal isOpen={isRulesOpen} setClose={setRulesClose} />
      </Suspense>
    </Navbar>
  );
};

interface JoinGroupModalProps {
  isOpen: boolean;
  setClose: () => void;
  inviteData?: GroupInviteDataFragment;
}

const JoinGroupModal: React.FC<JoinGroupModalProps> = ({ isOpen, setClose, inviteData }) => {
  const { t } = useTranslation(['groups']);
  const router = useRouter();
  const [, acceptInvite] = useAcceptInvitationMutation();

  const handleInviteAccept = async () => {
    if (inviteData && inviteData.public_id) {
      try {
        await acceptInvite({
          invitationId: inviteData.public_id,
        });
        setClose();
        await router.push({ pathname: '/app/groups/[groupSlug]', query: { groupSlug: inviteData.group?.slug } });

        showToast({
          title: t('groups:groupJoined'),
          description: t('groups:youJoinedTheGroup', { groupName: inviteData.group?.name }),
          type: 'success',
        });
      } catch (e) {
        console.error(e);
      }
    }
  };
  const groupName = inviteData?.group?.name;
  return (
    <Modal isOpen={isOpen} onClose={setClose}>
      <div className="flex flex-col justify-center items-center mt-6">
        <img
          className="w-20 h-20 rounded-full mb-2"
          src={inviteData?.group?.photo_url ?? DEFAULT_GROUP_PICTURE}
          alt="group"
        />
        <div className="font-black text-3xl mb-8 text-center">
          <Trans i18nKey="login:groupInviteWording">
            The <span className="text-blue-500">{{ groupName }}</span> group invites you to join them.
          </Trans>
        </div>
        <div className="flex flex-col space-y-2 mb-3">
          <Button onClick={handleInviteAccept}>{t('groups:joinTheGroup')}</Button>
          <Button onClick={setClose} variant="secondary">
            {t('groups:doNotJoinTheGroup')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
