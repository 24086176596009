import React, { useEffect } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { LoadingFullScreen } from './LoadingFullScreen';
import { NavbarLayout } from './NavbarLayout';
import { useIsCurrentUserAdmin } from '../hooks/useAuthContext';

export const AdminLayout: React.FC = ({ children }) => {
  const router = useRouter();
  const { user, loading: userLoading } = useCurrentUser();
  const isAdmin = useIsCurrentUserAdmin();

  useEffect(() => {
    if (!userLoading && !isAdmin) {
      router.push('/app');
    }
  }, [isAdmin, router, user, userLoading]);

  if (!user) {
    return <LoadingFullScreen debugLabel="admin no user" />;
  }
  if (!isAdmin) {
    return <LoadingFullScreen debugLabel="admin user not admin" />;
  }

  return <NavbarLayout>{children}</NavbarLayout>;
};
