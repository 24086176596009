import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Seo({ title }: { title?: string }) {
  const router = useRouter();
  const meta = {
    title: title || 'Pronostix — Euro 2020',
    description: `Fais tes pronos sur l’Euro 2020 et défie tes amis grâce aux groupes privés et au classement en temps réel. Gratuit, sans publicité et sans utilisation de données.`,
    image: 'https://pronostix.io/assets/cover.jpg',
    type: 'website',
  };

  return (
    <Head>
      <title>{meta.title}</title>
      <link rel="icon" href="/favicon.svg" />
      <link rel="mask-icon" href="/favicon.svg" color="#286EFB" />
      <meta name="robots" content="follow, index" />
      <meta name="description" content={meta.description} />
      <meta property="og:url" content={`https://pronostix.io${router.asPath}`} />
      <link rel="canonical" href={`https://pronostix.io${router.asPath}`} />
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content="Pronostix" />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@PronostixCM" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
    </Head>
  );
}
