import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { Dropdown } from '../Dropdown';
import { ArrowDown, Heart, Logout, Profile, Warning } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { auth } from '../../services/firebase';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Popover } from '@headlessui/react';
import { DEFAULT_PROFILE_PICTURE } from '../../constants';
import { useIsCurrentUserAdmin } from '../../hooks/useAuthContext';

export const ProfileDropdown = ({
  isOpen,
  onToggle,
}: {
  isOpen: boolean;
  onToggle: () => void;
}): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const { profile, user } = useCurrentUser();
  const isUserAdmin = useIsCurrentUserAdmin();

  const logout = async () => {
    await auth.signOut();
  };
  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={onToggle}
      content={
        <>
          <ProfileDropdownHeader
            email={user?.email ?? ''}
            picture={user?.photoUrl ?? DEFAULT_PROFILE_PICTURE}
            pseudo={user?.pseudo ?? ''}
          />
          <ProfileMenuItem href="/app/settings" className="mt-2" icon={<Profile />}>
            {t('common:nav.profile.my-account')}
          </ProfileMenuItem>
          {isUserAdmin && (
            <ProfileMenuItem href="/admin" className="mt-2" icon={<Warning />}>
              Admin
            </ProfileMenuItem>
          )}
          <ProfileMenuItem href="/support-us" isBlue icon={<Heart />}>
            {t('common:nav.profile.support-us')}
          </ProfileMenuItem>
          <ProfileMenuItem icon={<Logout />} onClick={() => logout()}>
            {t('common:logout')}
          </ProfileMenuItem>
        </>
      }
    >
      <ProfileButton
        isActive={isOpen}
        onClick={onToggle}
        points={profile?.points?.totalPoints ?? 0}
        picture={user?.photoUrl ?? DEFAULT_PROFILE_PICTURE}
        pseudo={user?.pseudo ?? ''}
      />
    </Dropdown>
  );
};

const ProfileButton: React.FC<{
  isActive: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  pseudo: string;
  points: number;
  picture: string;
}> = ({ isActive, onClick, pseudo, points, picture }) => {
  return (
    <div
      className={clsx('group flex space-x-4 items-center cursor-pointer h-16', {
        'text-black': !isActive,
        'text-blue-500': isActive,
      })}
      onClick={onClick}
    >
      <img className="rounded-full w-10 h-10" src={picture} alt="profile" />
      <div className="flex-col hidden lg:flex">
        <span className="text-base">{pseudo}</span>
        <span
          className={clsx('text-sm', {
            'text-gray-500': !isActive,
          })}
        >
          {points} points
        </span>
      </div>
      <ArrowDown className={clsx('w-6 h-6', { 'text-gray-300 group-hover:text-gray-500': !isActive })} />
    </div>
  );
};

const ProfileDropdownHeader: React.FC<{ pseudo?: string; picture?: string; email?: string }> = ({
  pseudo,
  picture,
  email,
}) => (
  <div className="flex pb-4 px-2 items-center relative">
    <img className="rounded-full w-12 h-12" src={picture} alt="profile" />
    <div className="flex flex-col ml-4 pt-2">
      <span className="text-black font-black text-lg">{pseudo}</span>
      <span className="text-gray-500 text-base">{email}</span>
    </div>
    <div className="absolute bottom-0 h-px w-full bg-gray-100" />
  </div>
);

// Todo make this accept href OR onClick but not both
type ProfileMenuItemType = {
  icon: JSX.Element;
  isBlue?: boolean;
  className?: string;
  href?: string;
  onClick?: () => void;
};

const ProfileMenuItem: React.FC<ProfileMenuItemType> = ({
  children,
  icon,
  isBlue = false,
  className,
  href,
  onClick,
}) => {
  const menuItemClassname = clsx(
    'p-2 hover:bg-gray-100 rounded-lg flex items-center cursor-pointer w-full',
    {
      'hover:bg-blue-100': isBlue,
      'text-blue-500': isBlue,
      'text-black': !isBlue,
    },
    className,
  );

  const iconBackgroundClassname = clsx('rounded-full w-8 h-8 bg-gray-100 flex justify-center items-center', {
    'bg-blue-100': isBlue,
  });

  const Body = (
    <>
      {icon && (
        <span className="mr-4">
          <div className={iconBackgroundClassname}>{icon}</div>
        </span>
      )}
      <span>{children}</span>
    </>
  );

  if (href) {
    return (
      <Popover.Button as="div">
        <Link href={href}>
          <a className={menuItemClassname}>{Body}</a>
        </Link>
      </Popover.Button>
    );
  }

  return (
    <button className={menuItemClassname} onClick={onClick}>
      {Body}
    </button>
  );
};
