import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { formatRelative, format as formatDate, add, isAfter } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const dateFnsLocales = {
  'fr-FR': fr,
  fr,
  'en': enUS,
};

const formatRelativeLocale = {
  fr: {
    lastWeek: "eeee 'dernier à' p",
    yesterday: "'hier à' p",
    today: "'aujourd’hui à' p",
    tomorrow: "'demain à' p'",
    nextWeek: "eeee 'à' p",
    other: "P 'à' p", // Difference: Add time to the date
  },
  en: {
    lastWeek: "'last' eeee 'at' p",
    yesterday: "'yesterday at' p",
    today: "'today at' p",
    tomorrow: "'tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: "P 'at' p", // Difference: Add time to the date
  },
} as const;

type localeType = keyof typeof dateFnsLocales;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: false,
    nonExplicitSupportedLngs: true,
    whitelist: ['fr', 'en'],

    react: {
      wait: true,
      useSuspense: false,
    },

    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng = 'en') => {
        if (!value || value === '' || value === undefined || value === null) {
          return '';
        }

        if (!format) {
          return value;
        }

        const [type, mask] = format.split('|');
        const currentLocale = dateFnsLocales[lng as localeType];

        const validLng = lng.startsWith('fr') ? 'fr' : 'en';

        const locale = {
          ...dateFnsLocales[validLng as localeType],
          // TODO fix type definition
          // @ts-ignore
          formatRelative: (token: string) => formatRelativeLocale[validLng][token],
        };

        if (type === 'date') {
          return formatDate(value, mask, { locale: currentLocale });
        }
        if (type === 'relative') {
          const fiveDayAgo = add(new Date(), { days: -5 });

          if (isAfter(value, fiveDayAgo)) {
            return formatRelative(value, new Date(), { locale });
          }
          return formatDate(value, mask, { locale: currentLocale });
        }
        return value;
      },
    },
  });

export default i18n;
