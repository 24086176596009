import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { ProfileDropdown } from '../ProfileDropdown';
import { NavigationTabs } from '../NavigationTabs';
import { IconButton } from '../IconButton';
import { useToggle } from '../../hooks/useToggle';
import { PronostixLogo, Home, Match, Star, Friends, Feedback } from '../../assets/icons';
import { LoadingFullScreen } from '../../layout/LoadingFullScreen';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { FeedbackFish } from '@feedback-fish/react';

export const Navbar: React.FC = ({ children }) => {
  const [isDropdownOpen, toggle] = useToggle();
  const { t } = useTranslation(['nav']);
  const { loading, user } = useCurrentUser();
  const tabs = [
    {
      label: t('nav:home'),
      route: '/app',
      icon: Home,
      exact: true,
    },
    {
      label: t('nav:games'),
      route: '/app/matches',
      icon: Match,
    },
    {
      label: t('nav:ranking'),
      route: '/app/ranking',
      icon: Star,
    },
    {
      label: t('nav:groups'),
      route: '/app/groups',
      icon: Friends,
    },
  ];
  if (loading) {
    return <LoadingFullScreen debugLabel="navbar - current user loading" />;
  }

  if (!user) {
    return <LoadingFullScreen debugLabel="navbar - no current user" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <nav className="flex w-full justify-between items-center h-16 border-b border-gray-300 px-4 bg-white">
        <Link href="/app">
          <a>
            <PronostixLogo className="text-blue-500 h-4 w-auto cursor-pointer" />
          </a>
        </Link>
        <div className="hidden md:flex h-full bg-white">
          <NavigationTabs tabs={tabs} />
        </div>
        <div className="flex items-center">
          <FeedbackFish projectId="40bf40541aac9c" userId={user.id}>
            <IconButton
              className="text-[#fd6eb3] bg-[#fbeff0] hover:bg-[#f8cae1] mr-6"
              alt="feedback"
              icon={Feedback}
            ></IconButton>
          </FeedbackFish>
          <ProfileDropdown isOpen={isDropdownOpen} onToggle={toggle} />
        </div>
      </nav>
      <div className="overflow-y-scroll flex-1 py-8 md:px-10 px-4 bg-gray-100">
        <div className="max-w-290 mx-auto text-black">{children}</div>
      </div>
      <div className="flex md:hidden h-12 w-full shadow-custom justify-center bg-white border-t border-gray-300">
        <NavigationTabs tabs={tabs} />
      </div>
    </div>
  );
};
