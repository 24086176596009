import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { NavLink } from '../ActiveNavLink';

interface Tab {
  label: string;
  route: string;
  icon: React.FC<{ className: string }>;
  exact?: boolean;
}
export interface NavigationTabsProps {
  tabs: Tab[];
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs }) => {
  return (
    <AnimateSharedLayout>
      <ol className="flex h-full space-x-1 md:space-x-2">
        {tabs.map(({ label, route, icon, exact }, i) => {
          const Icon = icon;

          return (
            <motion.li
              key={i}
              className="relative w-20 md:w-30 lg:w-32 items-center justify-center flex text-base"
              animate
            >
              <NavLink
                href={route}
                exact={exact ?? false}
                className="block relative flex flex-col items-center justify-center hover:bg-gray-100 w-full h-full lg:h-auto py-2 rounded-lg"
              >
                <Icon className="h-6 w-6" aria-hidden="true" />
                <span className="hidden sr-only md:not-sr-only md:block font-semibold">{label}</span>
              </NavLink>
            </motion.li>
          );
        })}
      </ol>
    </AnimateSharedLayout>
  );
};
