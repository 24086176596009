import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

function setViewHeight() {
  const vh = `${window.innerHeight * 0.01}px`;
  const oldVh = document.documentElement.style.getPropertyValue('--vh');
  if (vh !== oldVh) {
    document.documentElement.style.setProperty('--vh', vh);
  }
}

export const watchForHeightChange$ = () => {
  setViewHeight();
  return fromEvent(window, 'resize')
    .pipe(debounceTime(300))
    .subscribe(() => {
      setViewHeight();
    });
};
